import React, { useCallback } from 'react'
import { useNavigate } from 'react-router'

import { Icon, Link } from './styled'
import { NavLinkProps } from './types'

/**
 * Navigation Links within the NavBar
 * @see NavBar
 *
 * @param {NavLinkProps} props
 * @returns {JSX.Element}
 *
 * ```tsx
 *  <NavLink icon={faUserCircle} title="Profile" url="/profile" />
 * ```
 */
const NavLink = ({
  icon,
  title,
  url,
  size,
  isActive,
  onClick,
}: NavLinkProps): JSX.Element => {
  const navigation = useNavigate()

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(url ?? 'click')
    } else {
      navigation(url ?? '/')
    }
  }, [navigation, onClick, url])

  return (
    <Link onClick={() => handleClick()} className={isActive ? 'active' : ''}>
      <Icon src={icon} />
    </Link>
  )
}

export default NavLink
