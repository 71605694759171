import { log } from '../_utilities/logging'
import { doesExist, formatErrorObject } from '../_utilities/utils'
import { decryptText, encryptText } from './cryptography'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getLocalStorageItem = (key: string, defaultValue: any): Promise<string> =>
  new Promise((resolve, _) => {
    try {
      const value = localStorage.getItem(key)

      if (doesExist(value) && value !== 'undefined') {
        const decryptedValue = decryptText(value, key)
        resolve(decryptedValue)
      } else {
        resolve(defaultValue)
      }
    } catch (error) {
      log(
        'storage.ts',
        'getLocalStorageItem',
        'error',
        'Error accessing AsyncStorage',
        formatErrorObject(error),
        true,
      )
      resolve(defaultValue)
    }
  })

const setLocalStorageItem = (key: string, value: string): Promise<boolean> =>
  new Promise((resolve, _) => {
    try {
      if (value !== 'undefined') {
        const encryptedValue = encryptText(value, key)
        localStorage.setItem(key, encryptedValue)

        resolve(true)
      } else {
        resolve(false)
      }
    } catch (error) {
      log(
        'storage.ts',
        'setLocalStorageItem',
        'error',
        'Error accessing AsyncStorage',
        formatErrorObject(error),
        true,
      )
      resolve(false)
    }
  })

const checkLocalStorageItem = (key: string): Promise<boolean> =>
  new Promise((resolve, _) => {
    try {
      const value = localStorage.getItem(key)
      resolve(doesExist(value) && value !== 'undefined')
    } catch (error) {
      log(
        'storage.ts',
        'checkLocalStorageItem',
        'error',
        'Error accessing AsyncStorage',
        formatErrorObject(error),
        true,
      )
      resolve(false)
    }
  })

const removeLocalStorageItem = (key: string): Promise<boolean> =>
  new Promise((resolve, _) => {
    try {
      localStorage.removeItem(key)
      resolve(true)
    } catch (error) {
      log(
        'storage.ts',
        'removeLocalStorageItem',
        'error',
        'Error accessing AsyncStorage',
        formatErrorObject(error),
        true,
      )
      resolve(false)
    }
  })

export {
  checkLocalStorageItem,
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
}
