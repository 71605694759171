import React, { useCallback, useMemo } from 'react'
import { DefaultTheme } from 'styled-components'
import { useNavigate } from 'react-router'

import { colours, fontSize } from '../../_globals/theme'
import { generateBackgroundAnimation } from '../../_utilities/utils'
import {
  RestyledButton,
  FlairBackground,
  FlairButton,
  ButtonContainer,
} from './styled'
import { ButtonProps } from './types'

/**
 * A button! Wow! Wonder what that does
 *
 * @param {ButtonProps} props
 * @returns {JSX.Element}
 *
 * ```tsx
 * <Button
 *  text='Add to Order',
 *  theme='main',
 *  size='large',
 *  callback={() => handleAddToOrderClick()},
 *  display='block',
 * />
 * ```
 */
const Button = ({
  text,
  theme,
  size,
  width,
  callback,
  navigationUrl,
  elementId,
  display,
  isDisabled,
  offsetBottom,
  offsetLeft,
  offsetRight,
  offsetTop,
  offsetMode,
}: ButtonProps): JSX.Element => {
  const navigation = useNavigate()

  const buttonTheme = useMemo(() => {
    const newTheme: DefaultTheme = {
      background: colours.blue,
      colour: 'white',
      fontSize: fontSize.regular,
      paddingTop: '15px',
      paddingBottom: '15px',
      paddingLeft: '15px',
      paddingRight: '15px',
      opacity: isDisabled === true ? 0.5 : 1,
      cursor: isDisabled === true ? 'no-drop' : 'pointer',
      isVisible: isDisabled !== true,
      width: width || '80%',
    }

    switch (theme) {
      case 'dark':
        newTheme.background = colours.background
        newTheme.colour = 'white'
        break
      case 'light':
        newTheme.background = colours.lightGrey
        newTheme.border = colours.grey
        newTheme.colour = 'black'
        break
      case 'main':
        newTheme.background = colours.blue
        newTheme.colour = 'white'
        break
      case 'success':
        newTheme.background = 'green'
        newTheme.colour = 'black'
        break
      case 'error':
        newTheme.background = 'red'
        newTheme.colour = 'black'
        break
      default:
        newTheme.background = colours.lightGrey
        newTheme.colour = 'white'
    }

    if (size === 'small') {
      newTheme.fontSize = fontSize.small
      newTheme.paddingTop = '3px'
      newTheme.paddingBottom = '3px'
      newTheme.paddingLeft = '8px'
      newTheme.paddingRight = '8px'
      newTheme.width = 'auto'
    } else if (size === 'large') {
      newTheme.fontSize = fontSize.title
      newTheme.paddingTop = '10px'
      newTheme.paddingBottom = '10px'
      newTheme.paddingLeft = '30px'
      newTheme.paddingRight = '30px'
    }

    return newTheme
  }, [isDisabled, width, theme, size])

  const flairTheme = useMemo(() => {
    const newTheme: DefaultTheme = {
      ...buttonTheme,
      background: generateBackgroundAnimation('colour'),
    }

    return newTheme
  }, [buttonTheme])

  const containerTheme = useMemo(() => {
    const newTheme: DefaultTheme = {
      width: width || '100%',
      display: display || 'block',
    }

    return newTheme
  }, [width, display])

  const handleClick = useCallback(() => {
    if (callback) {
      // eslint-disable-next-line callback-return
      callback()
    } else if (navigationUrl && navigationUrl !== '') {
      const from = window.location.pathname.split('/')[0]
      navigation(`${navigationUrl}?from=${from}`)
    }
  }, [callback, navigation, navigationUrl])

  return (
    <ButtonContainer theme={containerTheme}>
      {theme === 'flair' ? (
        <FlairBackground theme={flairTheme} onClick={() => handleClick()}>
          <FlairButton theme={flairTheme}>{text}</FlairButton>
        </FlairBackground>
      ) : (
        <RestyledButton
          theme={buttonTheme}
          type="button"
          onClick={() => handleClick()}>
          {text}
        </RestyledButton>
      )}
    </ButtonContainer>
  )
}

export default Button
