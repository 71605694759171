import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import AlignmentContainer from '../../../components/alignment-container/AlignmentContainer'

import Button from '../../../components/button/Button'
import DropdownInput from '../../../components/dropdown-input/DropdownInput'
import { DropdownData } from '../../../components/dropdown-input/types'
import Loading from '../../../components/loading/Loading'
import PageBackButton from '../../../components/page-back-button/PageBackButton'
import PageContainer from '../../../components/page-container/PageContainer'
import PrettyFormContainer from '../../../components/pretty-form-container/PrettyFormContainer'
import Spacer from '../../../components/spacer/Spacer'
import TextInput from '../../../components/text-input/TextInput'
import TextElement from '../../../components/text/Text'
import { isUserAdmin } from '../../../_data/admin'
import { defaultInvitedUser } from '../../../_defaults/user'
import { doFunctionsCall } from '../../../_globals/custom-firebase/custom-firebase'
import { NotificationContext } from '../../../_globals/notifications/notification-context'
import { DatabaseCompany } from '../../../_types/company'
import { InvitedUser as InvitedUserType } from '../../../_types/user'
import { deepCopy } from '../../../_utilities/utils'
import { InvitedUserPageProps } from './types'

const InvitedUser = (): JSX.Element => {
  const { id } = useParams<InvitedUserPageProps>()
  const { showNotification } = useContext(NotificationContext)
  const navigation = useNavigate()
  const [pageError, setPageError] = useState<string>('')
  const [isApiBusy, setIsApiBusy] = useState<boolean>(true)
  const [invitedUser, setInvitedUser] = useState<InvitedUserType>()
  const [companyPossibilities, setCompanyPossibilities] = useState<
    DatabaseCompany[]
  >([])

  const companyDropdownData = useMemo<DropdownData[]>(
    () =>
      companyPossibilities.map(company => ({
        displayValue: company.commonName,
        id: company.id,
      })),
    [companyPossibilities],
  )

  const handlePageReady = useCallback(() => {
    isUserAdmin().then(isAdmin => {
      if (isAdmin === false) {
        navigation('/')
        return null
      }

      if (id === 'new') {
        doFunctionsCall('Admin', {
          signature: 'Company-GetAll',
        }).then(companyResponse => {
          if (companyResponse.code === 500) {
            setPageError('There was an error getting the companies')
            return null
          }

          setCompanyPossibilities(JSON.parse(companyResponse.data))
          setInvitedUser(deepCopy(defaultInvitedUser))

          setIsApiBusy(false)
        })
      } else {
        doFunctionsCall('Admin', {
          signature: 'InvitedUser-Get',
          id,
        }).then(data => {
          if (data.code === 500) {
            setPageError('There was an error getting the invited user')
            return null
          }

          doFunctionsCall('Admin', {
            signature: 'Company-GetAll',
          }).then(companyResponse => {
            if (companyResponse.code === 500) {
              setPageError('There was an error getting the companies')
              return null
            }

            setCompanyPossibilities(JSON.parse(companyResponse.data))
            setInvitedUser(JSON.parse(data.data))

            setIsApiBusy(false)
          })
        })
      }
    })
  }, [id, navigation])

  const handleUpdateFormData = useCallback(
    (attribute: string, newValue: string) => {
      setInvitedUser(previous => ({ ...previous, [attribute]: newValue }))
    },
    [],
  )

  const handleSaveData = useCallback(() => {
    if (id === 'new') {
      doFunctionsCall('Admin', {
        signature: 'InvitedUser-Create',
        email: invitedUser.email,
        companyId: invitedUser.companyId,
      }).then(result => {
        if (result.code === 500) {
          setPageError('There was an error creating the invited user')
          return null
        }

        showNotification({
          title: 'Invited user created successfully',
          type: 'success',
          dismissAfter: 2500,
        })

        navigation(`/admin/invited-user/${result.data}`)
      })
    } else {
      doFunctionsCall('Admin', {
        signature: 'InvitedUser-Update',
        id,
        email: invitedUser.email,
        companyId: invitedUser.companyId,
      }).then(result => {
        if (result.code === 500) {
          setPageError('There was an error updating the invited user')
          return null
        }

        showNotification({
          title: 'Invited user updated successfully',
          type: 'success',
          dismissAfter: 2500,
        })
      })
    }
  }, [
    id,
    invitedUser?.companyId,
    invitedUser?.email,
    navigation,
    showNotification,
  ])

  return (
    <PageContainer
      offsetLeft="20px"
      offsetRight="20px"
      offsetTop="20px"
      offsetMode="padding"
      pageError={pageError}
      allowNotifications={true}
      allowUnauthenticated={false}
      onPageReady={() => handlePageReady()}>
      <PageBackButton
        text="Back to Invited Users"
        urlOnClick="/admin/invited-users"
      />
      <TextElement
        text="Invited Users"
        theme="h1"
        alignment="center"
        display="block"
      />
      <Spacer direction="vertical" amount="20px" display="block" />
      {isApiBusy === false && invitedUser ? (
        <>
          <PrettyFormContainer
            title="Email"
            description={[]}
            formId="email"
            formContent={
              <TextInput
                initialValue={invitedUser.email}
                onTextChange={newValue =>
                  handleUpdateFormData('email', newValue)
                }
              />
            }
          />
          <PrettyFormContainer
            title="Company"
            description={[]}
            formId="companyId"
            formContent={
              <DropdownInput
                unselectedDisplayValue="Select a company"
                data={companyDropdownData}
                initialValue={invitedUser.companyId}
                width="100%"
                onChange={newValue =>
                  handleUpdateFormData('companyId', newValue)
                }
              />
            }
          />
          <Spacer direction="vertical" amount="20px" display="block" />
          <AlignmentContainer align="center" display="block">
            <Button
              text={id === 'new' ? 'Save User' : 'Save Changes'}
              theme="light"
              callback={() => handleSaveData()}
            />
          </AlignmentContainer>
        </>
      ) : (
        <Loading type="large" />
      )}
      <Spacer direction="vertical" amount="50px" display="block" />
    </PageContainer>
  )
}

export default InvitedUser
