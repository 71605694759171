import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router'

import LinkIcon from '../../_assets/images/icons/up-right-from-square-solid.svg'
import Icon from '../../_assets/images/icon.png'

import PageContainer from '../../components/page-container/PageContainer'
import Spacer from '../../components/spacer/Spacer'
import TextInput from '../../components/text-input/TextInput'
import TextElement from '../../components/text/Text'
import { colours } from '../../_globals/theme'
import { searchHaystack } from './helpers'
import { Hit, IconContainer, LinkIconContainer } from './styled'
import { SearchHit } from './types'

const Search = (): JSX.Element => {
  const navigation = useNavigate()
  const [hits, setHits] = useState<SearchHit[]>([])
  const [query, setQuery] = useState<string>('')

  const handleSearch = useCallback((newQuery: string) => {
    setQuery(newQuery)

    if (newQuery === '') {
      setHits([])
    } else {
      setHits(searchHaystack(newQuery))
    }
  }, [])

  const handleSelectHit = useCallback(
    (page: string) => {
      if (page === 'home') {
        navigation('/')
      } else if (page === 'quote-calculator') {
        navigation('/quote-calculator')
      } else if (page === 'materials-estimator') {
        navigation('/materials-estimator')
      } else if (page === 'initiate-warranty') {
        navigation('/initiate-warranty')
      } else if (page === 'installer-guide') {
        navigation('/installer-guide')
      }
    },
    [navigation],
  )

  return (
    <PageContainer
      offsetLeft="20px"
      offsetRight="20px"
      offsetTop="20px"
      offsetMode="padding"
      allowNotifications={true}
      allowUnauthenticated={false}>
      <TextElement
        text="Search"
        theme="h1"
        alignment="center"
        display="block"
      />
      <Spacer direction="vertical" amount="20px" display="block" />
      <TextInput
        placeholder="Search for anything"
        display="block"
        onTextChange={newValue => handleSearch(newValue)}
      />
      {query === '' ? (
        <>
          <Spacer direction="vertical" amount="20px" display="block" />
          <IconContainer src={Icon} />
          <Spacer direction="vertical" amount="10px" display="block" />
          <TextElement
            text="Search for anything"
            theme="paragraph"
            alignment="center"
            display="block"
            colour={colours.grey}
            italics={true}
          />
        </>
      ) : null}
      {query !== '' && hits.length === 0 ? (
        <>
          <Spacer direction="vertical" amount="20px" display="block" />
          <TextElement
            text="No results found"
            theme="paragraph"
            alignment="center"
            display="block"
          />
        </>
      ) : null}
      {query !== '' && hits.length > 0 ? (
        <>
          {hits.map(hit => (
            <Hit key={hit.page} onClick={() => handleSelectHit(hit.page)}>
              <LinkIconContainer src={LinkIcon} />
              <TextElement text={hit.title} theme="paragraph" display="block" />
            </Hit>
          ))}
        </>
      ) : null}
    </PageContainer>
  )
}

export default Search
