import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import AlignmentContainer from '../../../components/alignment-container/AlignmentContainer'

import Button from '../../../components/button/Button'
import Loading from '../../../components/loading/Loading'
import PageContainer from '../../../components/page-container/PageContainer'
import Spacer from '../../../components/spacer/Spacer'
import Table from '../../../components/table/Table'
import { TableData } from '../../../components/table/types'
import TextElement from '../../../components/text/Text'
import { isUserAdmin } from '../../../_data/admin'
import { doFunctionsCall } from '../../../_globals/custom-firebase/custom-firebase'
import { InvitedUser } from '../../../_types/user'

const InvitedUsers = (): JSX.Element => {
  const navigation = useNavigate()
  const [users, setUsers] = useState<InvitedUser[]>([])
  const [isApiBusy, setIsApiBusy] = useState<boolean>(true)

  const handleViewUser = useCallback(
    (userId: string) => {
      navigation(`/admin/invited-user/${userId}`)
    },
    [navigation],
  )

  const tableData = useMemo(() => {
    const rows: TableData[][] = []

    users
      .sort((a, b) => {
        if (a.email < b.email) {
          return -1
        } else if (a.email > b.email) {
          return 1
        }

        return 0
      })
      .forEach(user => {
        const row: TableData[] = [
          {
            content: user.email,
          },
          {
            content: (
              <Button
                text="View Invited User"
                theme="light"
                size="small"
                callback={() => handleViewUser(user.id)}
              />
            ),
          },
        ]

        rows.push(row)
      })

    return rows
  }, [handleViewUser, users])

  const handlePageReady = useCallback(() => {
    isUserAdmin().then(isAdmin => {
      if (isAdmin === false) {
        navigation('/')
        return null
      }

      doFunctionsCall('HighPriority', {
        signature: 'InvitedUser-Get',
      }).then(data => {
        if (data.code === 200) {
          const fetchedUsers: InvitedUser[] = JSON.parse(data.data)

          const filteredUsers = fetchedUsers.filter(
            user => user.id !== 'always-present',
          )

          setUsers(filteredUsers)
          setIsApiBusy(false)
        } else {
          console.error(data)
        }
      })
    })
  }, [navigation])

  return (
    <PageContainer
      offsetLeft="20px"
      offsetRight="20px"
      offsetTop="20px"
      offsetMode="padding"
      allowNotifications={true}
      allowUnauthenticated={false}
      onPageReady={() => handlePageReady()}>
      <TextElement
        text="Invited Users"
        theme="h1"
        alignment="center"
        display="block"
      />
      <Spacer direction="vertical" amount="20px" display="block" />
      <AlignmentContainer align="center" display="block">
        <Button
          text="Invite New User"
          theme="main"
          callback={() => navigation('/admin/invited-user/new')}
        />
      </AlignmentContainer>
      <Spacer direction="vertical" amount="20px" display="block" />
      {isApiBusy ? (
        <Loading type="large" />
      ) : (
        <Table
          headerDirection="horizontal"
          alignment="center"
          headers={[{ content: 'Email' }, { content: 'Actions' }]}
          content={tableData}
        />
      )}
      <Spacer direction="vertical" amount="50px" display="block" />
    </PageContainer>
  )
}

export default InvitedUsers
