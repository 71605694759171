import styled from 'styled-components'

import { viewportBreakpoints } from '../../_globals/theme'

const Container = styled.button`
  display: block;
  width: 100%;
  background: none;
  border: none;
  text-align: left;
  padding: 5px 15px;
  font-size: 100%;

  @media only screen and (max-width: ${viewportBreakpoints.phone[1]}px) {
    padding-top: 10px;
  }
`

const Icon = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  font-size: 100%;
`

const ButtonText = styled.p`
  color: ${props => props.theme.colour};
  display: inline-block;
  vertical-align: middle;
  padding-left: 10px;
  cursor: pointer;
  font-size: 100%;
`
ButtonText.defaultProps = {
  theme: {
    colour: 'black',
  },
}

export { Container, Icon, ButtonText }
