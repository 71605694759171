import styled from 'styled-components'
import { colours } from '../../../../../../_globals/theme'

const Title = styled.p`
  text-align: center;
  transition: color 0.3s;
`

const Icon = styled.img`
  height: 30px;
  display: block;
  margin: auto;
  padding-bottom: 10px;
  transition: filter 0.3s;
`

const Link = styled.a`
  display: inline-block;
  cursor: pointer;
  padding: 15px 0;
  font-size: 0.9rem;
  flex: 1;
  transition: background-color 0.3s;
  height: 100%;

  &.active {
    background-color: ${colours.lightGrey};

    ${Icon} {
      filter: invert(100%);
    }

    ${Title} {
      color: white;
    }
  }
`

export { Link, Icon, Title }
