import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { DefaultTheme } from 'styled-components'
import { useLocation, useNavigate } from 'react-router'

import MenuIcon from '../../../../_assets/images/icons/bars-solid.svg'
import SearchIcon from '../../../../_assets/images/icons/magnifying-glass-solid.svg'
import CloseIcon from '../../../../_assets/images/icons/xmark-solid.svg'
import LogoSquare from '../../../../_assets/images/icon.png'
import LogoLong from '../../../../_assets/images/logo.png'

import { RootState } from '../../../../_globals/state-store'
import { useAppSelector } from '../../../../_globals/hooks'
import { constants } from '../../../../_utilities/config'
import { NavBarProps } from './types'
import {
  CloseIconContainer,
  Icon,
  IconContainer,
  LinkBar,
  LinkBarElement,
  LinkBarText,
  NavContainer,
  NavContentContainer,
} from './styled'
import NavLink from './components/nav-link/NavLink'

const themeAppSelector = (state: RootState) => state.theme
const userAppSelector = (state: RootState) => state.user

const NavBar = ({ pagePosition }: NavBarProps): JSX.Element => {
  const reactLocation = useLocation()
  const themeSelector = useAppSelector(themeAppSelector)
  const userSelector = useAppSelector(userAppSelector)
  const navigation = useNavigate()
  const [path, setPath] = useState('')
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const containerTheme = useMemo(() => {
    const theme: DefaultTheme = {
      borderTop: 'none',
      borderBottom: 'none',
      height: '50px',
    }

    if (pagePosition === 'top') {
      theme.borderBottom = `1px solid grey`
    } else {
      theme.borderTop = `1px solid grey`
    }

    return theme
  }, [pagePosition])

  useEffect(() => {
    setPath(reactLocation.pathname.toLowerCase())
  }, [reactLocation.pathname])

  const handleMenuClick = useCallback(
    (url: string) => {
      setIsMenuOpen(false)

      if (url === '/initiate-warranty') {
        const query = `?company=${userSelector.companyLegalName}&installer_email=${userSelector.email}`

        navigation(url + query)
      } else if (url === 'youtube') {
        window.open(`${constants.youtube}?from=app.stormflow.ca`, '_blank')
      } else {
        navigation(url)
      }
    },
    [navigation, userSelector.companyLegalName, userSelector.email],
  )

  const searchElement = useMemo(() => {
    if (themeSelector.windowWidth <= 800) {
      return (
        <NavLink icon={SearchIcon} title="Search" url="/search" size="large" />
      )
    }

    return null
  }, [themeSelector.windowWidth])

  const logoElement = useMemo(
    () =>
      themeSelector.windowWidth <= 400 ? (
        <IconContainer className="icon">
          <Icon src={LogoSquare} onClick={() => handleMenuClick('/')} />
        </IconContainer>
      ) : (
        <IconContainer
          className={`logo ${window.innerWidth > 800 ? 'static' : ''}`}>
          <Icon src={LogoLong} onClick={() => handleMenuClick('/')} />
        </IconContainer>
      ),
    [handleMenuClick, themeSelector.windowWidth],
  )

  const linksElement = useMemo(() => {
    if (
      (themeSelector.windowWidth <= 800 && isMenuOpen === true) ||
      themeSelector.windowWidth > 800
    ) {
      return (
        <LinkBar className={isMenuOpen ? 'open' : ''}>
          <CloseIconContainer>
            <NavLink
              icon={CloseIcon}
              title="Close"
              size="large"
              onClick={() => setIsMenuOpen(false)}
            />
          </CloseIconContainer>
          {isMenuOpen ? (
            <LinkBarElement
              className={path === '' ? 'active' : ''}
              onClick={() => handleMenuClick('/')}>
              <LinkBarText>Home</LinkBarText>
            </LinkBarElement>
          ) : null}
          <LinkBarElement
            className={path.includes('quote') ? 'active' : ''}
            onClick={() => handleMenuClick('/quote-calculator')}>
            <LinkBarText>
              Cost of
              <br />
              Materials
            </LinkBarText>
          </LinkBarElement>
          <LinkBarElement
            className={path.includes('materials') ? 'active' : ''}
            onClick={() => handleMenuClick('/materials-estimator')}>
            <LinkBarText>
              Materials
              <br />
              Estimator
            </LinkBarText>
          </LinkBarElement>
          <LinkBarElement
            className={path.includes('warranty') ? 'active' : ''}
            onClick={() => handleMenuClick('/initiate-warranty')}>
            <LinkBarText>
              Initiate
              <br />
              Warranty
            </LinkBarText>
          </LinkBarElement>
          <LinkBarElement
            className={path.includes('installer') ? 'active' : ''}
            onClick={() => handleMenuClick('/installer-guide')}>
            <LinkBarText>
              Installer
              <br />
              Guide
            </LinkBarText>
          </LinkBarElement>
          <LinkBarElement
            className={path.includes('youtube') ? 'active' : ''}
            onClick={() => handleMenuClick('youtube')}>
            <LinkBarText>
              Video
              <br />
              Guides
            </LinkBarText>
          </LinkBarElement>
          {themeSelector.windowWidth > 800 ? (
            <NavLink
              icon={SearchIcon}
              title="Search"
              url="/search"
              size="large"
            />
          ) : null}
        </LinkBar>
      )
    } else if (themeSelector.windowWidth <= 800) {
      return (
        <NavLink
          icon={MenuIcon}
          title="Menu"
          onClick={() => setIsMenuOpen(true)}
          size="large"
        />
      )
    }

    return null
  }, [handleMenuClick, isMenuOpen, path, themeSelector.windowWidth])

  return (
    <NavContainer theme={containerTheme} id="main_navigation_bar">
      <NavContentContainer>
        {searchElement}
        {logoElement}
        {linksElement}
      </NavContentContainer>
    </NavContainer>
  )
}

export default NavBar
