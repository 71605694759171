import styled from 'styled-components'
import { colours } from '../../_globals/theme'

const Hit = styled.button`
  border: 1px solid ${colours.blue};
  background: none;
  display: block;
  width: 90%;
  max-width: 300px;
  margin: 15px auto;
  padding: 10px 15px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  transition: box-shadow 0.3s;

  & p {
    flex: 1;
  }

  &:hover {
    box-shadow: 2px 2px 3px 1px #00aeef4f;
  }
`

const IconContainer = styled.img`
  width: 80px;
  display: block;
  margin: 100px auto 0 auto;
`

const LinkIconContainer = styled.img`
  height: 20px;
  padding-right: 15px;
  opacity: 0.5;
`

export { Hit, IconContainer, LinkIconContainer }
