import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useNavigate } from 'react-router'
import { StyledFirebaseAuth } from 'react-firebaseui'
import firebaseui from 'firebaseui'
import {
  browserLocalPersistence,
  EmailAuthProvider,
  getAuth,
  GoogleAuthProvider,
  setPersistence,
} from 'firebase/auth'

import LogoImage from '../../_assets/images/logo.png'
import AuthorizedImage from '../../_assets/images/authorized.png'
import IconImage from '../../_assets/images/icon.png'

import { NotificationContext } from '../../_globals/notifications/notification-context'
import Spacer from '../../components/spacer/Spacer'
import TextElement from '../../components/text/Text'
import Button from '../../components/button/Button'
import AlignmentContainer from '../../components/alignment-container/AlignmentContainer'
import { GenericObject, LoginProviderId } from '../../_types/globals'
import { DisplayUser } from '../../_types/user'
import { useAppDispatch } from '../../_globals/hooks'
import { userSlice } from '../../_globals/user/user-slice'
import { doFunctionsCall } from '../../_globals/custom-firebase/custom-firebase'
import PageContainer from '../../components/page-container/PageContainer'
import { DatabaseCompany } from '../../_types/company'
import {
  AuthorizedImageContainer,
  IconImageContainer,
  LoginContainer,
  LoginStartContainer,
  LoginStartContent,
  LoginStartContentContainer,
  LogoImageContainer,
  Terms,
  TermsContainer,
} from './styled'

const Login = (): JSX.Element => {
  const { showNotification } = useContext(NotificationContext)
  const navigation = useNavigate()
  const appDispatch = useAppDispatch()
  const [loginPhase, setLoginPhase] = useState<string>('start')
  const [termsOfService, setTermsOfService] = useState<string>('')
  const [isApiBusy, setIsApiBusy] = useState<boolean>(false)
  const [isUnauthorized, setIsUnauthorized] = useState<boolean>(false)

  const firebaseAuth = useMemo(() => {
    const localFirebaseAuth = getAuth()
    setPersistence(localFirebaseAuth, browserLocalPersistence)

    return localFirebaseAuth
  }, [])

  const handleSignInSuccess = useCallback(
    authResult => {
      if (isApiBusy === true) {
        showNotification({
          title: 'Loading, please try again in a moment',
          type: 'error',
          dismissAfter: 2500,
        })

        return false
      }

      setIsApiBusy(true)

      const loginProviderId: LoginProviderId =
        authResult?.additionalUserInfo?.providerId === 'google.com'
          ? 'google.com'
          : 'email'

      const user: GenericObject = {
        displayName: authResult?.user?.auth?.currentUser?.displayName ?? '',
        email: authResult?.user?.auth?.currentUser?.email ?? '',
        loginProviderId,
      }

      doFunctionsCall('HighestPriority', {
        signature: 'User-Create',
        user,
        from: 'admin',
      }).then(result => {
        if (result.code === 500) {
          console.error(result)

          setIsApiBusy(false)
          showNotification({
            title: 'An error has occurred, please try again',
            type: 'error',
            dismissAfter: 3000,
          })
        }

        const databaseUser: DisplayUser = JSON.parse(result.data)

        doFunctionsCall('HighestPriority', {
          signature: 'Company-Get',
          companyId: databaseUser.companyId,
        })
          .then(data => {
            if (data.code === 200) {
              const company: DatabaseCompany = JSON.parse(data.data)

              databaseUser.companyCommonName = company.commonName
              databaseUser.companyLegalName = company.officialName

              appDispatch(userSlice.actions.saveUser(databaseUser))

              showNotification({
                title: 'Welcome!',
                type: 'success',
                dismissAfter: 1500,
              })

              navigation('/')
            } else if (data.code === 500) {
              console.error(data, databaseUser.companyId)
              setIsUnauthorized(true)
              showNotification({
                title: 'An error has occurred, please try again',
                type: 'error',
                dismissAfter: 3000,
              })
            }
          })
          .catch((error: Error) => {
            console.error(error)
            setIsUnauthorized(true)
            showNotification({
              title: 'An error has occurred, please try again',
              type: 'error',
              dismissAfter: 3000,
            })
          })
      })

      return false
    },
    [appDispatch, isApiBusy, navigation, showNotification],
  )

  const handleSignInFailure = useCallback(
    result => {
      console.error(result)

      showNotification({
        title: 'An error occurred, please try that again',
        type: 'error',
        autoHide: false,
      })

      setIsApiBusy(false)
    },
    [showNotification],
  )

  const firebaseLoginOptions = useMemo<firebaseui.auth.Config>(
    () => ({
      signInFlow: 'popup',
      autoUpgradeAnonymousUsers: true,
      siteName: 'Stormflow Surfacing',
      callbacks: {
        signInSuccessWithAuthResult: handleSignInSuccess,
        signInFailure: handleSignInFailure,
      },
      signInOptions: [
        GoogleAuthProvider.PROVIDER_ID,
        EmailAuthProvider.PROVIDER_ID,
      ],
    }),
    [handleSignInFailure, handleSignInSuccess],
  )

  const fetchData = useCallback(() => {
    doFunctionsCall('HighPriority', {
      signature: 'Utility-GetTerms',
      attribute: 'termsOfUse',
    }).then(data => {
      if (data.code === 200) {
        setTermsOfService(data.data)
      } else {
        console.error(data)

        showNotification({
          title: 'An error occurred, please try that again',
          type: 'error',
          autoHide: false,
        })
      }
    })
  }, [showNotification])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <PageContainer
      backgroundColour="white"
      showNavBar={false}
      allowNotifications={true}
      allowUnauthenticated={true}>
      {loginPhase === 'start' ? (
        <LoginStartContainer>
          <LoginStartContentContainer>
            <LoginStartContent>
              <AuthorizedImageContainer src={AuthorizedImage} />
            </LoginStartContent>
            <LoginStartContent>
              <AlignmentContainer align="center" display="block">
                <LogoImageContainer src={LogoImage} />
                <Button
                  text="Login"
                  theme="dark"
                  callback={() => setLoginPhase('terms')}
                />
              </AlignmentContainer>
            </LoginStartContent>
          </LoginStartContentContainer>
        </LoginStartContainer>
      ) : null}
      {loginPhase === 'terms' ? (
        <LoginContainer>
          <IconImageContainer src={IconImage} />
          <TextElement
            theme="title"
            alignment="center"
            display="block"
            text="Login"
          />
          <TextElement
            theme="paragraph"
            alignment="center"
            display="block"
            text="to Stormflow Surfacing"
          />
          <Spacer direction="vertical" amount="30px" display="block" />
          <TextElement
            theme="paragraph"
            alignment="center"
            display="block"
            text="You must first agree to our Terms of Service"
          />
          <Spacer direction="vertical" amount="30px" display="block" />
          <Terms
            value={termsOfService === '' ? 'Loading...' : termsOfService}
          />
          <TermsContainer>
            <Button
              text="Decline"
              theme="light"
              callback={() => null}
              display="inline-block"
              width="50%"
            />
            <Button
              text="Accept"
              theme="dark"
              callback={() => setLoginPhase('login')}
              display="inline-block"
              width="50%"
            />
          </TermsContainer>
        </LoginContainer>
      ) : null}
      {loginPhase === 'login' ? (
        <LoginContainer>
          <IconImageContainer src={IconImage} />
          <TextElement
            theme="title"
            alignment="center"
            display="block"
            text="Login"
          />
          <TextElement
            theme="paragraph"
            alignment="center"
            display="block"
            text="to Stormflow Surfacing"
          />
          <Spacer direction="vertical" amount="30px" display="block" />
          <StyledFirebaseAuth
            firebaseAuth={firebaseAuth}
            uiConfig={firebaseLoginOptions}
          />
          {isUnauthorized ? (
            <TextElement
              text="You are not authorized to use this application"
              theme="paragraph"
              alignment="center"
              display="block"
            />
          ) : null}
        </LoginContainer>
      ) : null}
    </PageContainer>
  )
}

export default Login
