import React, { useCallback, useContext, useMemo, useState } from 'react'
import { DefaultTheme } from 'styled-components'
import CheckboxInput from '../../components/checkbox-input/CheckboxInput'

import PageContainer from '../../components/page-container/PageContainer'
import Spacer from '../../components/spacer/Spacer'
import TextElement from '../../components/text/Text'
import { doFunctionsCall } from '../../_globals/custom-firebase/custom-firebase'
import { NotificationContext } from '../../_globals/notifications/notification-context'
import { addThirdPartyLibrary, onLibraryLoad } from '../../_utilities/dom'
import { FormContainer, FormTarget, Terms } from './styled'

const InitiateWarranty = (): JSX.Element => {
  const { showNotification } = useContext(NotificationContext)
  const [terms, setTerms] = useState<string>('')
  const [agreedToTerms, setAgreedToTerms] = useState<boolean>(false)
  const [certifiedWeather, setCertifiedWeather] = useState<boolean>(false)

  const hubspotContainerTheme = useMemo<DefaultTheme>(
    () => ({
      display: agreedToTerms && certifiedWeather ? 'block' : 'none',
    }),
    [agreedToTerms, certifiedWeather],
  )

  const handlePageReady = useCallback(() => {
    addThirdPartyLibrary(
      'hubspot-warranty',
      '//js.hsforms.net/forms/embed/v2.js',
    )
    onLibraryLoad('hbspt').then(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ;(window as any).hbspt.forms.create({
        region: 'na1',
        portalId: '5241363',
        formId: '03ba86ca-25c1-4777-8fc1-821ad62aea17',
        target: '#hubspot-warranty-target',
      })
    })

    doFunctionsCall('HighPriority', {
      signature: 'Utility-GetTerms',
      attribute: 'warranty',
    }).then(data => {
      if (data.code === 200) {
        setTerms(data.data)
      } else {
        console.error(data)

        showNotification({
          title: 'Could not fetch data, please reload the page',
          type: 'error',
          autoHide: false,
        })
      }
    })
  }, [showNotification])

  return (
    <PageContainer
      offsetLeft="20px"
      offsetRight="20px"
      offsetTop="20px"
      offsetMode="padding"
      allowNotifications={true}
      allowUnauthenticated={false}
      onPageReady={() => handlePageReady()}>
      <TextElement
        text="Initiate Warranty"
        theme="h1"
        alignment="center"
        display="block"
      />
      <Spacer direction="vertical" amount="10px" display="block" />
      <TextElement
        text="When finished your installation project, complete the below warranty submission form to initiate a warranty."
        theme="paragraph"
        alignment="center"
        display="block"
      />
      <Spacer direction="vertical" amount="20px" display="block" />
      {agreedToTerms === false || certifiedWeather === false ? (
        <>
          <Spacer direction="vertical" amount="20px" display="block" />
          <Terms value={terms === '' ? 'Loading...' : terms} readOnly={true} />
          <CheckboxInput
            offsetTop="10px"
            offsetMode="padding"
            callback={newValue => setAgreedToTerms(newValue)}
            checkboxText="I have read and hereby agree to the above warranty."
            value={agreedToTerms}
            checkboxIdentifier="warranty"
          />
          <Spacer direction="vertical" amount="20px" display="block" />
          <CheckboxInput
            offsetTop="10px"
            offsetMode="padding"
            callback={newValue => setCertifiedWeather(newValue)}
            checkboxText="I certify this install was completed with no rain (within 6 hours) and temperatures between 8-32°C"
            value={certifiedWeather}
            checkboxIdentifier="weather"
          />
          <Spacer direction="vertical" amount="30px" display="block" />
        </>
      ) : null}
      <FormContainer theme={hubspotContainerTheme}>
        <FormTarget id="hubspot-warranty-target" />
      </FormContainer>
    </PageContainer>
  )
}

export default InitiateWarranty
