import appPackage from '../../package.json'
import { AppEnvironment } from '../_environments/types'
import {
  Environment as loadedEnvironment,
  Environment,
} from '../_environments/environment'
import { log } from './logging'
import { formatErrorObject } from './utils'

/**
 * Get configuration information about the webapp
 *
 * @returns {any}
 *
 *  ```ts
 * const appInfo = getAppInfo()
 * ```
 */
const getAppInfo = () => {
  let numberOfDependencies = -1

  try {
    const deps = Object.keys(appPackage.dependencies)
    numberOfDependencies = deps.length
  } catch (error) {
    log(
      'config.ts',
      'getAppInfo',
      'error',
      'Error fetching ',
      formatErrorObject(error),
      true,
    )
    numberOfDependencies = -1
  }

  return {
    appVersion: appPackage.version ?? 'unknown',
    appName: appPackage.name ?? 'unknown',
    environment: loadedEnvironment.ENV_CODE,
    numberOfDependencies,
  }
}

/**
 * Fetch the devices timezone
 *
 * @returns {string}
 *
 * ```ts
 * const timezone = getTimezone()
 * ```
 */
const getTimezone = () => {
  // eslint-disable-next-line new-cap
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return timezone
}

/**
 * Get environment settings for the webapp
 *
 * @returns {AppEnvironment}
 *
 * ```ts
 * getEnvironmentSettings()
 * ```
 */
const getEnvironmentSettings = (): AppEnvironment =>
  Environment ?? {
    ENV_CODE: 'prod',
    ENV_NAME: 'Production',
    consoleLogLevel: 1,
    FUNCTIONS_REGION: 'northamerica-northeast1',
    config: {
      apiKey: 'AIzaSyDd3CN6oMnOVqDcB2r7dEJCPj21rilTLQQ',
      authDomain: 'stormflow-installer-web-app.firebaseapp.com',
      projectId: 'stormflow-installer-web-app',
      storageBucket: 'stormflow-installer-web-app.appspot.com',
      messagingSenderId: '521117362551',
      appId: '1:521117362551:web:54ec621a05f4eee11d9972',
      measurementId: 'G-LKTY91MDRB',
    },
  }

const dateFormats = {
  fullMonthDayYear: 'MMMM d, yyyy',
  numericDayMonthYear: 'dd-MM-yyyy',
}

const constants = {
  helpEmail: 'brandon@stormflow.ca',
  youtube: 'https://www.youtube.com/channel/UChmbleepxBj_GwWB3if7CTA',
}

export {
  getAppInfo,
  getTimezone,
  getEnvironmentSettings,
  dateFormats,
  constants,
}
