import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import AlignmentContainer from '../../../components/alignment-container/AlignmentContainer'

import Button from '../../../components/button/Button'
import DropdownInput from '../../../components/dropdown-input/DropdownInput'
import { DropdownData } from '../../../components/dropdown-input/types'
import Loading from '../../../components/loading/Loading'
import PageBackButton from '../../../components/page-back-button/PageBackButton'
import PageContainer from '../../../components/page-container/PageContainer'
import PrettyFormContainer from '../../../components/pretty-form-container/PrettyFormContainer'
import Spacer from '../../../components/spacer/Spacer'
import TextInput from '../../../components/text-input/TextInput'
import TextElement from '../../../components/text/Text'
import { isUserAdmin } from '../../../_data/admin'
import { defaultDatabaseUser } from '../../../_defaults/user'
import { doFunctionsCall } from '../../../_globals/custom-firebase/custom-firebase'
import { NotificationContext } from '../../../_globals/notifications/notification-context'
import { DatabaseCompany } from '../../../_types/company'
import { DatabaseUser } from '../../../_types/user'
import { deepCopy } from '../../../_utilities/utils'
import { UserPageProps } from './types'

const User = (): JSX.Element => {
  const { id } = useParams<UserPageProps>()
  const navigation = useNavigate()
  const { showNotification } = useContext(NotificationContext)
  const [pageError, setPageError] = useState<string>('')
  const [isApiBusy, setIsApiBusy] = useState<boolean>(true)
  const [selectedUser, setSelectedUser] = useState<DatabaseUser>()
  const [companyPossibilities, setCompanyPossibilities] = useState<
    DatabaseCompany[]
  >([])

  const companyDropdownData = useMemo<DropdownData[]>(
    () =>
      companyPossibilities.map(company => ({
        displayValue: company.commonName,
        id: company.id,
      })),
    [companyPossibilities],
  )

  const roleDropdownData = useMemo<DropdownData[]>(
    () => [
      { displayValue: 'Admin', id: 'admin' },
      { displayValue: 'User', id: 'user' },
      { displayValue: 'Unauthorized', id: 'unauthorized' },
    ],
    [],
  )

  const handlePageReady = useCallback(() => {
    isUserAdmin().then(isAdmin => {
      if (isAdmin === false) {
        navigation('/')
        return null
      }

      if (id === 'new') {
        doFunctionsCall('Admin', {
          signature: 'Company-GetAll',
        }).then(companyResponse => {
          if (companyResponse.code === 500) {
            setPageError('There was an error getting the companies')
            return null
          }

          setCompanyPossibilities(JSON.parse(companyResponse.data))
          setSelectedUser(deepCopy(defaultDatabaseUser))

          setIsApiBusy(false)
        })
      } else {
        doFunctionsCall('HighestPriority', {
          signature: 'User-Get',
          userId: id,
        })
          .then(data => {
            if (data.code === 500) {
              setPageError('There was an error getting the user')
              return null
            }

            doFunctionsCall('Admin', {
              signature: 'Company-GetAll',
            })
              .then(companyResponse => {
                if (companyResponse.code === 500) {
                  setPageError('There was an error getting the companies')
                  return null
                }

                setCompanyPossibilities(JSON.parse(companyResponse.data))
                setSelectedUser(JSON.parse(data.data))
              })
              .catch((error: Error) => {
                setPageError(error.message)
              })
              .finally(() => {
                setIsApiBusy(false)
              })
          })
          .catch((error: Error) => {
            setPageError(error.message)
            setIsApiBusy(false)
          })
      }
    })
  }, [id, navigation])

  const handleUpdateFormData = useCallback(
    (attribute: string, newValue: string) => {
      setSelectedUser(previous => ({ ...previous, [attribute]: newValue }))
    },
    [],
  )

  const handleSaveData = useCallback(() => {
    doFunctionsCall('Admin', {
      signature: 'User-Update',
      id,
      displayName: selectedUser.displayName,
      email: selectedUser.email,
      companyId: selectedUser.companyId,
      role: selectedUser.role,
    }).then(result => {
      if (result.code === 500) {
        console.error(result)
        setPageError('There was an error updating the user')
        return null
      }

      showNotification({
        title: 'User Updated',
        type: 'success',
        dismissAfter: 2500,
      })
    })
  }, [
    id,
    selectedUser?.companyId,
    selectedUser?.displayName,
    selectedUser?.email,
    selectedUser?.role,
    showNotification,
  ])

  return (
    <PageContainer
      offsetLeft="20px"
      offsetRight="20px"
      offsetTop="20px"
      offsetMode="padding"
      pageError={pageError}
      allowNotifications={true}
      allowUnauthenticated={false}
      onPageReady={() => handlePageReady()}>
      <PageBackButton text="Back to Users" urlOnClick="/admin/users" />
      <TextElement text="User" theme="h1" alignment="center" display="block" />
      <Spacer direction="vertical" amount="20px" display="block" />
      {isApiBusy === false && selectedUser ? (
        <>
          <PrettyFormContainer
            title="Full Name"
            description={[]}
            formId="displayName"
            formContent={
              <TextInput
                initialValue={selectedUser.displayName}
                onTextChange={newValue =>
                  handleUpdateFormData('displayName', newValue)
                }
              />
            }
          />
          <PrettyFormContainer
            title="Email"
            description={[]}
            formId="email"
            formContent={
              <TextInput
                initialValue={selectedUser.email}
                onTextChange={newValue =>
                  handleUpdateFormData('email', newValue)
                }
              />
            }
          />
          <PrettyFormContainer
            title="Company"
            description={[]}
            formId="companyId"
            formContent={
              <DropdownInput
                unselectedDisplayValue="Select a Company"
                data={companyDropdownData}
                initialValue={selectedUser.companyId}
                width="100%"
                onChange={newValue =>
                  handleUpdateFormData('companyId', newValue)
                }
              />
            }
          />
          <PrettyFormContainer
            title="Role"
            description={[]}
            formId="role"
            formContent={
              <DropdownInput
                unselectedDisplayValue="Select a Role"
                data={roleDropdownData}
                initialValue={selectedUser.role}
                width="100%"
                onChange={newValue => handleUpdateFormData('role', newValue)}
              />
            }
          />
          <Spacer direction="vertical" amount="20px" display="block" />
          <AlignmentContainer align="center" display="block">
            <Button
              text="Save Changes"
              theme="light"
              callback={() => handleSaveData()}
            />
          </AlignmentContainer>
          <Spacer direction="vertical" amount="50px" display="block" />
        </>
      ) : (
        <Loading type="large" />
      )}
    </PageContainer>
  )
}

export default User
