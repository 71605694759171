import { doFunctionsCall } from '../_globals/custom-firebase/custom-firebase'

const isUserAdmin = (): Promise<boolean> =>
  new Promise(resolve => {
    doFunctionsCall('Utility', {
      signature: 'Secret-IsAdmin',
    })
      .then(response => {
        resolve(response.code === 200)
      })
      .catch((error: Error) => {
        console.error(error)
        resolve(false)
      })
  })

export { isUserAdmin }
