import React, { useCallback, useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import AlignmentContainer from '../../../components/alignment-container/AlignmentContainer'

import Button from '../../../components/button/Button'
import Loading from '../../../components/loading/Loading'
import PageBackButton from '../../../components/page-back-button/PageBackButton'
import PageContainer from '../../../components/page-container/PageContainer'
import PrettyFormContainer from '../../../components/pretty-form-container/PrettyFormContainer'
import Spacer from '../../../components/spacer/Spacer'
import TextInput from '../../../components/text-input/TextInput'
import TextElement from '../../../components/text/Text'
import { isUserAdmin } from '../../../_data/admin'
import { defaultDatabaseCompany } from '../../../_defaults/company'
import { doFunctionsCall } from '../../../_globals/custom-firebase/custom-firebase'
import { NotificationContext } from '../../../_globals/notifications/notification-context'
import { DatabaseCompany } from '../../../_types/company'
import { deepCopy } from '../../../_utilities/utils'
import { CompanyPageProps } from './types'

const InvitedUser = (): JSX.Element => {
  const { id } = useParams<CompanyPageProps>()
  const navigation = useNavigate()
  const { showNotification } = useContext(NotificationContext)
  const [pageError, setPageError] = useState<string>('')
  const [isApiBusy, setIsApiBusy] = useState<boolean>(true)
  const [selectedCompany, setSelectedCompany] = useState<DatabaseCompany>()

  const handlePageReady = useCallback(() => {
    isUserAdmin().then(isAdmin => {
      if (isAdmin === false) {
        navigation('/')
        return null
      }

      if (id === 'new') {
        doFunctionsCall('Admin', {
          signature: 'Company-GetAll',
        }).then(companyResponse => {
          if (companyResponse.code === 500) {
            setPageError('There was an error getting the companies')
            return null
          }

          setSelectedCompany(deepCopy(defaultDatabaseCompany))

          setIsApiBusy(false)
        })
      } else {
        doFunctionsCall('HighestPriority', {
          signature: 'Company-Get',
          companyId: id,
        }).then(data => {
          if (data.code === 500) {
            setPageError('There was an error getting the company')
            return null
          }

          setSelectedCompany(JSON.parse(data.data))

          setIsApiBusy(false)
        })
      }
    })
  }, [id, navigation])

  const handleUpdateFormData = useCallback(
    (attribute: string, newValue: string) => {
      setSelectedCompany(previous => ({ ...previous, [attribute]: newValue }))
    },
    [],
  )

  const handleSaveData = useCallback(() => {
    if (id === 'new') {
      doFunctionsCall('Admin', {
        signature: 'Company-Create',
        company: selectedCompany,
      })
        .then(result => {
          if (result.code === 500) {
            console.error(result)
            setPageError('There was an error creating the company')
            return null
          }

          showNotification({
            title: 'Company created successfully',
            type: 'success',
            dismissAfter: 2500,
          })

          navigation(`/admin/company/${result.data}`)
        })
        .catch((error: Error) => {
          console.error(error)
          setPageError('There was an error creating the company')
        })
    } else {
      doFunctionsCall('Admin', {
        signature: 'Company-Update',
        company: selectedCompany,
      })
        .then(result => {
          if (result.code === 500) {
            console.error(result)
            setPageError('There was an error updating the company')
            return null
          }

          showNotification({
            title: 'Company updated successfully',
            type: 'success',
            dismissAfter: 2500,
          })
        })
        .catch((error: Error) => {
          console.error(error)
          setPageError('There was an error updating the company')
        })
    }
  }, [id, navigation, selectedCompany, showNotification])

  return (
    <PageContainer
      offsetLeft="20px"
      offsetRight="20px"
      offsetTop="20px"
      offsetMode="padding"
      pageError={pageError}
      allowNotifications={true}
      allowUnauthenticated={false}
      onPageReady={() => handlePageReady()}>
      <PageBackButton text="Back to Companies" urlOnClick="/admin/companies" />
      <TextElement
        text="Company"
        theme="h1"
        alignment="center"
        display="block"
      />
      <Spacer direction="vertical" amount="20px" display="block" />
      {isApiBusy === false ? (
        <>
          <PrettyFormContainer
            title="Common Name"
            description={['Trade Name']}
            formId="commonName"
            formContent={
              <TextInput
                initialValue={selectedCompany.commonName}
                onTextChange={newValue =>
                  handleUpdateFormData('commonName', newValue)
                }
              />
            }
          />
          <PrettyFormContainer
            title="Official Name"
            description={['Legal Entity Name']}
            formId="officialName"
            formContent={
              <TextInput
                initialValue={selectedCompany.officialName}
                onTextChange={newValue =>
                  handleUpdateFormData('officialName', newValue)
                }
              />
            }
          />
          <PrettyFormContainer
            title="Main Contact Name"
            description={[]}
            formId="mainContactName"
            formContent={
              <TextInput
                initialValue={selectedCompany.mainContactName}
                onTextChange={newValue =>
                  handleUpdateFormData('mainContactName', newValue)
                }
              />
            }
          />
          <PrettyFormContainer
            title="Main Contact Email"
            description={[]}
            formId="mainEmail"
            formContent={
              <TextInput
                initialValue={selectedCompany.mainEmail}
                onTextChange={newValue =>
                  handleUpdateFormData('mainEmail', newValue)
                }
              />
            }
          />
          <PrettyFormContainer
            title="Main Contact Phone"
            description={[]}
            formId="mainPhone"
            formContent={
              <TextInput
                initialValue={selectedCompany.mainPhone}
                onTextChange={newValue =>
                  handleUpdateFormData('mainPhone', newValue)
                }
              />
            }
          />
          <Spacer direction="vertical" amount="20px" display="block" />
          <AlignmentContainer align="center" display="block">
            <Button
              text={id === 'new' ? 'Create Company' : 'Save Changes'}
              theme="light"
              callback={() => handleSaveData()}
            />
          </AlignmentContainer>
        </>
      ) : (
        <Loading type="large" />
      )}
      <Spacer direction="vertical" amount="50px" display="block" />
    </PageContainer>
  )
}

export default InvitedUser
