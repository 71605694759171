import React, { useEffect, useMemo, useState } from 'react'
import { DefaultTheme } from 'styled-components'

import { useAppSelector } from '../../_globals/hooks'
import { RootState } from '../../_globals/state-store'
import NavBar from './components/nav-bar/NavBar'
import { ContentContainer, NavMenuAndPageContainer } from './styled'
import { NavMenuProps } from './types'

const themeAppSelector = (state: RootState) => state.theme

/**
 * A menu for navigation
 *
 * @param {NavMenuProps} props
 * @returns {JSX.Element}
 *
 * ```tsx
 * <NavMenu
 *  hideNavMenu={themeSelector.showNavbar === false}
 *  pagePosition="top"
 *  sticky={true}
 * >
 *  <p>Am I menu content? Yes, yes I believe I am.</p>
 * </NavMenu>
 * ```
 */
const NavMenu = ({
  pagePosition,
  sticky,
  children,
  hideNavMenu,
}: NavMenuProps): JSX.Element => {
  const themeSelector = useAppSelector(themeAppSelector)
  const [pageHeight, setPageHeight] = useState<string>('')

  const contentTheme = useMemo(() => {
    const theme: DefaultTheme = themeSelector.showNavbar
      ? {
          height: pageHeight === '' ? '100vh' : pageHeight,
          justify: sticky ? 'height' : 'min-height',
        }
      : {
          height: 'auto',
          justify: 'height',
        }

    return theme
  }, [pageHeight, sticky, themeSelector?.showNavbar])

  useEffect(() => {
    let useableHeight = Math.round(themeSelector.windowHeight - 90)

    if (hideNavMenu === false) {
      useableHeight -= 50
    }

    setPageHeight(`${useableHeight.toString()}px`)
  }, [hideNavMenu, themeSelector.isPhoneView, themeSelector.windowHeight])

  return (
    <NavMenuAndPageContainer>
      {pagePosition === 'top' && hideNavMenu === false ? (
        <NavBar pagePosition="top" />
      ) : null}
      <ContentContainer theme={contentTheme}>{children}</ContentContainer>
      {pagePosition === 'bottom' && hideNavMenu === false ? (
        <NavBar pagePosition="bottom" />
      ) : null}
    </NavMenuAndPageContainer>
  )
}

export default NavMenu
