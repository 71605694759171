import CryptoJS from 'crypto-js'

const encryptText = (content: string, nonce: string) => {
  const cipher = CryptoJS.AES.encrypt(content, nonce)

  return cipher.toString()
}

const decryptText = (content: string, nonce: string) => {
  const bytes = CryptoJS.AES.decrypt(content, nonce)
  const originalContent = bytes.toString(CryptoJS.enc.Utf8)

  return originalContent
}

export { encryptText, decryptText }
