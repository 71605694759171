import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  display: ${props => props.theme.display};
  max-width: 800px;
  margin: 20px auto;
  border-radius: 10px;
  background-color: #e8e8e8;
`
Container.defaultProps = {
  theme: {
    display: 'flex',
  },
}

const InfoContainer = styled.div`
  display: ${props => props.theme.display};
  width: 100%;
  max-width: ${props => props.theme.maxWidth};
  padding: 10px;
  vertical-align: top;
`
InfoContainer.defaultProps = {
  theme: {
    display: 'inline-block',
    maxWidth: '300px',
  },
}

const ContentContainer = styled.div`
  display: ${props => props.theme.display};
  flex: ${props => (props.theme.display === 'block' ? 'none' : 1)};
  max-width: ${props => (props.theme.display === 'block' ? '100%' : 'none')};
  padding: 10px;
  vertical-align: top;
`
ContentContainer.defaultProps = {
  theme: {
    display: 'inline-block',
  },
}

const TagContainer = styled.div`
  text-align: left;
`

const Tag = styled.p`
  display: inline-block;
  border: 2px solid black;
  padding: 2px 10px;
  font-size: 0.6rem;
  border-radius: 10px;
  margin-right: 10px;
  color: black;
  background-color: white;
  letter-spacing: 0.05rem;

  &.public {
    border-color: #3d9970;
  }
  &.required {
    border-color: #ff4136;
  }
  &.private {
    border-color: #b10dc9;
  }
  &.optional {
    border-color: #ff9b00;
  }
`

export { Container, InfoContainer, ContentContainer, TagContainer, Tag }
