import React from 'react'

import PageContainer from '../../components/page-container/PageContainer'
import Spacer from '../../components/spacer/Spacer'
import TextElement from '../../components/text/Text'
import { FormTarget, FormContainer, FormIframe } from './styled'

const MaterialsEstimator = (): JSX.Element => (
  <PageContainer
    offsetLeft="20px"
    offsetRight="20px"
    offsetMode="padding"
    allowNotifications={true}
    allowUnauthenticated={false}>
    <Spacer direction="vertical" amount="20px" display="block" />
    <TextElement
      text="Materials Estimator"
      theme="h1"
      alignment="center"
      display="block"
    />
    <Spacer direction="vertical" amount="10px" display="block" />
    <TextElement
      text="Complete the fields below to calculate the amount of material for your project."
      theme="paragraph"
      alignment="center"
      display="block"
    />
    <Spacer direction="vertical" amount="40px" display="block" />
    <FormContainer>
      <FormTarget
        className="op-interactive"
        id="64385933ac36a65926f1c68f"
        data-title="Materials Estimator"
        data-url="https://stormflowsurfacing.outgrow.us/64385933ac36a65926f1c68f?vHeight=1">
        <FormIframe
          title="Materials Estimator"
          src="https://stormflowsurfacing.outgrow.us/64385933ac36a65926f1c68f?custom=1&seamless=1"
        />
      </FormTarget>
    </FormContainer>
  </PageContainer>
)

export default MaterialsEstimator
