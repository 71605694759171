/**
 * Scroll a dom element into the viewport
 *
 * @param {string} elementId - Element ID
 * @returns {void}
 *
 * ```ts
 * scrollElementIntoView('checkout-email')
 * ```
 */
const scrollElementIntoView = (elementId: string) => {
  try {
    const element = document.getElementById(elementId)

    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    })
  } catch (error) {
    console.error(error)
  }
}

/**
 * Gently scroll to the top of the page
 *
 * @returns {void}
 *
 * ```ts
 * toTop()
 * ```
 */
const toTop = () => {
  try {
    window.scroll(0, 0)
  } catch (error) {
    console.error(error)
  }
}

/**
 * Set page header data
 *
 * @param {string} title - Tab title
 * @param {string} description - Meta description (SEO)
 * @param {boolean} [addLaunchpad] - Add a launchpad flair to the description
 * @param {string} [thumbnail] - Page thumbnail
 * @returns {void}
 *
 * ```ts
 * setPageData(product.name, product.description, true, product.featureImage)
 * ```
 */
const setPageData = (
  title: string,
  description: string,
  addLaunchpad?: boolean,
  thumbnail?: string,
) => {
  const titleElement: HTMLTitleElement =
    document.getElementsByTagName('title')[0]
  const descriptionElement: HTMLMetaElement = document.querySelector(
    'meta[name=description]',
  )
  const thumbnailElement: HTMLMetaElement = document.querySelector(
    'meta[name=thumbnail]',
  )

  titleElement.textContent = title
  descriptionElement.content = description

  if (thumbnail) {
    thumbnailElement.content = thumbnail
  }
}

const addThirdPartyLibrary = (id: string, url: string) => {
  const scriptElement = document.getElementById(id)

  if (!scriptElement) {
    const newScriptElement = document.createElement('script')

    newScriptElement.id = id
    newScriptElement.src = url
    newScriptElement.async = true

    document.head.append(newScriptElement)
  }
}

const onLibraryLoad = (objectToTest: string): Promise<void> =>
  new Promise(resolve => {
    const interval = setInterval(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((window as any)[objectToTest]) {
        clearInterval(interval)
        resolve()
      }
    }, 500)
  })

export {
  scrollElementIntoView,
  toTop,
  setPageData,
  addThirdPartyLibrary,
  onLibraryLoad,
}
