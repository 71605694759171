import styled from 'styled-components'

const Dropdown = styled.select`
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.colour};
  width: ${props => props.theme.width};
  border: 1px solid black;
  padding: 10px;
  border-radius: 5px;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  max-width: 300px;
  font-size: 100%;
`
Dropdown.defaultProps = {
  theme: {
    background: '#ffffff',
    colour: '#000000',
    width: 'initial',
  },
}

const DropdownOption = styled.option``

export { Dropdown, DropdownOption }
