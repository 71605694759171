import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AlignmentContainer from '../../../components/alignment-container/AlignmentContainer'

import Button from '../../../components/button/Button'
import Loading from '../../../components/loading/Loading'
import PageContainer from '../../../components/page-container/PageContainer'
import Spacer from '../../../components/spacer/Spacer'
import Table from '../../../components/table/Table'
import { TableData } from '../../../components/table/types'
import TextElement from '../../../components/text/Text'
import { isUserAdmin } from '../../../_data/admin'
import { doFunctionsCall } from '../../../_globals/custom-firebase/custom-firebase'
import { DatabaseCompany } from '../../../_types/company'

const Companies = (): JSX.Element => {
  const navigation = useNavigate()
  const [companyList, setCompanyList] = useState<DatabaseCompany[]>([])
  const [isApiBusy, setIsApiBusy] = useState<boolean>(true)

  const handleViewCompany = useCallback(
    (userId: string) => {
      navigation(`/admin/company/${userId}`)
    },
    [navigation],
  )

  const tableData = useMemo(() => {
    const rows: TableData[][] = []

    companyList
      .sort((a, b) => {
        if (a.commonName < b.commonName) {
          return -1
        } else if (a.commonName > b.commonName) {
          return 1
        }

        return 0
      })
      .forEach(company => {
        const row: TableData[] = [
          {
            content: company.commonName,
          },
          {
            content: (
              <Button
                text="View Company"
                theme="light"
                size="small"
                callback={() => handleViewCompany(company.id)}
              />
            ),
          },
        ]

        rows.push(row)
      })

    return rows
  }, [companyList, handleViewCompany])

  const handlePageReady = useCallback(() => {
    isUserAdmin().then(isAdmin => {
      if (isAdmin === false) {
        navigation('/')
        return null
      }

      doFunctionsCall('Admin', {
        signature: 'Company-GetAll',
      }).then(data => {
        if (data.code === 200) {
          const fetchedCompanies: DatabaseCompany[] = JSON.parse(data.data)
          setCompanyList(fetchedCompanies)
        } else {
          console.error(data)
        }

        setIsApiBusy(false)
      })
    })
  }, [navigation])

  return (
    <PageContainer
      offsetLeft="20px"
      offsetRight="20px"
      offsetTop="20px"
      offsetMode="padding"
      allowNotifications={true}
      allowUnauthenticated={false}
      onPageReady={() => handlePageReady()}>
      <TextElement
        text="Companies"
        theme="h1"
        alignment="center"
        display="block"
      />
      <Spacer direction="vertical" amount="20px" display="block" />
      <AlignmentContainer align="center" display="block">
        <Button
          text="New Company"
          theme="main"
          callback={() => navigation('/admin/company/new')}
        />
      </AlignmentContainer>
      <Spacer direction="vertical" amount="20px" display="block" />
      {isApiBusy ? (
        <Loading type="large" />
      ) : (
        <Table
          headerDirection="horizontal"
          alignment="center"
          headers={[{ content: 'Company' }, { content: 'Actions' }]}
          content={tableData}
        />
      )}
      <Spacer direction="vertical" amount="50px" display="block" />
    </PageContainer>
  )
}

export default Companies
