import { DatabaseCompany } from '../_types/company'

const defaultDatabaseCompany: DatabaseCompany = {
  id: '',
  officialName: '',
  commonName: '',
  mainContactName: '',
  mainEmail: '',
  mainPhone: '',
  address: '',
  createdTimestamp: 0,
}

export { defaultDatabaseCompany }
