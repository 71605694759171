import styled from 'styled-components'

const FormContainer = styled.div`
  display: ${props => props.theme.display};
`
FormContainer.defaultProps = {
  theme: {
    display: 'none',
  },
}

const FormTarget = styled.div``

const Terms = styled.textarea`
  height: calc(100vh - 350px);
  min-height: 200px;
  max-height: 500px;
  display: block;
  width: 100%;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  line-height: 125%;
  margin: auto;
  font-size: 100%;
`

export { FormContainer, FormTarget, Terms }
