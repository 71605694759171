/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-nested-callbacks */
import React, { useCallback, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import DropdownInput from '../../components/dropdown-input/DropdownInput'
import { DropdownData } from '../../components/dropdown-input/types'
import Loading from '../../components/loading/Loading'
import PageContainer from '../../components/page-container/PageContainer'
import Spacer from '../../components/spacer/Spacer'
import TextElement from '../../components/text/Text'
import { doFunctionsCall } from '../../_globals/custom-firebase/custom-firebase'
import { DatabaseManual } from '../../_types/manual'
import { onLibraryLoad } from '../../_utilities/dom'
import {
  GuideImageContainer,
  PdfContainer,
  PdfTarget,
  Thumbnail,
  ThumbnailGrid,
} from './styled'
import { getAcrobatInstance } from './helpers'

const InstallerGuide = (): JSX.Element => {
  const [queryParameters] = useSearchParams()
  const [pageError, setPageError] = useState<string>('')
  const [manuals, setManuals] = useState<DatabaseManual[]>([])
  const [selectedManual, setSelectedManual] = useState<DatabaseManual>()
  const [hasTappedImage, setHasTappedImage] = useState<boolean>(false)

  const displayManual = useMemo(
    () => ({
      display: selectedManual && hasTappedImage === true ? 'block' : 'none',
    }),
    [hasTappedImage, selectedManual],
  )

  const manualDropdownData = useMemo<DropdownData[]>(
    () =>
      manuals
        .filter(manual => manual.displayOrder !== -1)
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map(manual => ({
          displayValue: manual.name,
          id: manual.id,
        })),
    [manuals],
  )

  const handleSelectManual = useCallback(
    (
      manualId: string,
      fetchedManuals?: DatabaseManual[],
      skipThumbnail?: boolean,
    ) => {
      setHasTappedImage(Boolean(fetchedManuals))

      const sanitizedManuals = fetchedManuals || manuals
      const foundSelectedManual = sanitizedManuals.find(
        manual => manual.id === manualId,
      )
      if (foundSelectedManual) {
        setSelectedManual(foundSelectedManual)
        document.getElementById('acrobat-viewer').innerHTML = ''

        onLibraryLoad('AdobeDC').then(() => {
          getAcrobatInstance().previewFile(
            {
              content: {
                location: {
                  url: foundSelectedManual.url,
                },
              },
              metaData: {
                fileName: foundSelectedManual.name,
              },
            },
            {
              embedMode: 'LIGHT_BOX',
              showPrintPDF: true,
            },
          )
        })

        if (skipThumbnail) {
          setHasTappedImage(true)
        }
      } else {
        setSelectedManual(null)
      }
    },
    [manuals],
  )

  const handlePageReady = useCallback(() => {
    doFunctionsCall('HighestPriority', {
      signature: 'Manual-Get',
    })
      .then(data => {
        if (data.code === 200) {
          const fetchedManuals: DatabaseManual[] = JSON.parse(data.data)
          setManuals(fetchedManuals)

          if (queryParameters.has('guide')) {
            const guideId = queryParameters.get('guide')

            if (guideId) {
              const foundManual = fetchedManuals.find(
                manual => manual.id === guideId,
              )

              if (foundManual) {
                handleSelectManual(foundManual.id, fetchedManuals, true)
              }
            }
          }
        } else if (data.code === 500) {
          setPageError(
            'There was an error fetching the manuals. Please try again later.',
          )
        }
      })
      .catch((error: Error) => {
        console.error(error)
        setPageError(
          'There was an error fetching the manuals. Please try again later.',
        )
      })
  }, [handleSelectManual, queryParameters])

  return (
    <PageContainer
      offsetLeft="20px"
      offsetRight="20px"
      offsetTop="20px"
      offsetMode="padding"
      pageError={pageError}
      allowNotifications={true}
      allowUnauthenticated={false}
      onPageReady={() => handlePageReady()}>
      <TextElement
        text="Installer Guide"
        theme="h1"
        alignment="center"
        display="block"
      />
      <Spacer direction="vertical" amount="10px" display="block" />
      <TextElement
        text="Follow our installation manual below before starting your next project."
        theme="paragraph"
        alignment="center"
        display="block"
      />
      <Spacer direction="vertical" amount="20px" display="block" />
      {manuals.length > 0 ? (
        <>
          <DropdownInput
            unselectedDisplayValue="Select a manual"
            data={manualDropdownData}
            initialValue={selectedManual ? selectedManual.id : ''}
            width="100%"
            display="block"
            offsetLeft="auto"
            offsetRight="auto"
            offsetMode="margin"
            onChange={(manualId: string) => handleSelectManual(manualId)}
          />
          <Spacer direction="vertical" amount="20px" display="block" />
          {!selectedManual || (selectedManual && hasTappedImage) ? (
            <ThumbnailGrid>
              {manuals
                .filter(manual => manual.displayOrder !== -1)
                .map(manual => (
                  <Thumbnail
                    key={manual.id}
                    src={manual.photo}
                    onClick={() => handleSelectManual(manual.id, null, true)}
                  />
                ))}
            </ThumbnailGrid>
          ) : null}
          {selectedManual && hasTappedImage === false ? (
            <GuideImageContainer
              src={selectedManual.photo}
              onClick={() => setHasTappedImage(true)}
            />
          ) : null}
          <PdfContainer theme={displayManual}>
            <PdfTarget id="acrobat-viewer" />
          </PdfContainer>
          <Spacer direction="vertical" amount="20px" display="block" />
        </>
      ) : (
        <Loading type="large" />
      )}
    </PageContainer>
  )
}

export default InstallerGuide
