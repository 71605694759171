import React from 'react'
import ReactDOM from 'react-dom'
import { initializeApp } from 'firebase/app'

import './_globals/css/global-styles.scss'
import './_globals/css/global-colours.scss'
import './_globals/css/global-fonts.scss'
import './_globals/css/libraries.scss'

import { Provider } from 'react-redux'
import App from './App'
import MainThemer from './components/main-themer/MainThemer'
import { stateStore } from './_globals/state-store'
import * as serviceWorkerRegistration from './service-worker-registration'
import { getEnvironmentSettings } from './_utilities/config'

const appEnvironment = getEnvironmentSettings()
initializeApp(appEnvironment.config)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={stateStore}>
      <MainThemer>
        <App />
      </MainThemer>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

serviceWorkerRegistration.register({
  onUpdate: registration => {
    if (registration && registration.waiting) {
      // eslint-disable-next-line no-restricted-syntax
      console.log('new update found!')

      registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    }
  },
})
