import styled from 'styled-components'
import { colours } from '../../_globals/theme'

const LoginContainer = styled.div`
  max-width: 500px;
  width: 80%;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
`

const Logo = styled.img`
  width: 90%;
  margin: 20px auto;
  display: block;
`

const LoginButton = styled.button`
  width: 70%;
  background: none;
  border: none;
  cursor: pointer;
  display: block;
  margin: 20px auto;
`

const LoginImage = styled.img`
  width: 100%;
  transition: filter 0.3s;
  filter: ${props => (props.theme.isVisible ? 'none' : 'grayscale(100%)')};
`
LoginImage.defaultProps = {
  theme: {
    isVisible: true,
  },
}

const TermsContainer = styled.div`
  margin: 20px 0;
  width: 100%;
  text-align: center;
`

const LoginStartContainer = styled.div`
  height: 100vh;

  &::before {
    content: '';
    display: block;
    position: fixed;
    top: -100px;
    left: -100px;
    width: calc(100vw + 200px);
    height: calc(50% + 50px);
    background: ${colours.lightBlue};
    transform: rotate(-5deg);
  }
`

const LoginStartContentContainer = styled.div``

const LoginStartContent = styled.div`
  height: 45vh;
  min-height: 200px;
  position: relative;

  & > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const AuthorizedImageContainer = styled.img`
  max-width: 200px;
  width: 80%;
`

const LogoImageContainer = styled.img`
  max-width: 250px;
  width: 80%;
`

const IconImageContainer = styled.img`
  max-height: 50px;
  height: 100%;
  display: block;
  margin: 50px auto;
`

const Terms = styled.textarea`
  height: calc(100vh - 400px);
  min-height: 200px;
  max-height: 500px;
  display: block;
  width: 100%;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  line-height: 125%;
  font-size: 100%;
`

export {
  LoginContainer,
  Logo,
  LoginButton,
  LoginImage,
  TermsContainer,
  LoginStartContainer,
  LoginStartContent,
  LoginStartContentContainer,
  AuthorizedImageContainer,
  LogoImageContainer,
  IconImageContainer,
  Terms,
}
