import styled from 'styled-components'

const LogoContainer = styled.img`
  max-width: 80%;
  margin: 100px auto 0 auto;
  display: block;
`

const GuideImageContainer = styled.img`
  max-width: 400px;
  width: 90%;
  display: block;
  margin: 50px auto 0 auto;
  cursor: pointer;
`

const GuideContainer = styled.iframe`
  width: 100%;
  display: block;
  margin: 30px auto 30px auto;
  height: calc(100vh - 60px);
  max-width: 500px;
`

const PdfContainer = styled.div`
  display: ${props => props.theme.display};
`
PdfContainer.defaultProps = {
  theme: {
    display: 'none',
  },
}

const PdfTarget = styled.div``

const IconContainer = styled.img`
  width: 80px;
  display: block;
  margin: 100px auto 0 auto;
`

const ThumbnailGrid = styled.div`
  margin: 20px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
`

const Thumbnail = styled.img`
  width: 150px;
  height: 150px;
  cursor: pointer;
`

export {
  LogoContainer,
  GuideImageContainer,
  GuideContainer,
  PdfContainer,
  PdfTarget,
  IconContainer,
  ThumbnailGrid,
  Thumbnail,
}
