import styled from 'styled-components'

const FormContainer = styled.div`
  display: block !important;
  width: 100%;
  margin-bottom: 20px;
`

const FormTarget = styled.div`
  height: calc(100vh - 275px);
`

const FormIframe = styled.iframe`
  height: calc(100% - 20px);
  width: 100%;
  border: none;
`

export { FormContainer, FormTarget, FormIframe }
