/* eslint-disable no-restricted-syntax */
import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router'

import AlignmentContainer from '../../components/alignment-container/AlignmentContainer'
import Button from '../../components/button/Button'
import PageContainer from '../../components/page-container/PageContainer'
import Spacer from '../../components/spacer/Spacer'
import TextElement from '../../components/text/Text'
import { isUserAdmin } from '../../_data/admin'
import { useAppSelector } from '../../_globals/hooks'
import { RootState } from '../../_globals/state-store'
import { constants } from '../../_utilities/config'

const userAppSelector = (state: RootState) => state.user

const Home = (): JSX.Element => {
  const navigation = useNavigate()
  const userSelector = useAppSelector(userAppSelector)
  const [isBecky, setIsBecky] = useState(false)

  const handlePageClick = useCallback(
    (url: string) => {
      if (url === '/initiate-warranty') {
        const query = `?company=${userSelector.companyLegalName}&installer_email=${userSelector.email}`

        navigation(url + query)
      } else if (url === 'youtube') {
        window.open(`${constants.youtube}?from=app.stormflow.ca`, '_blank')
      } else {
        navigation(url)
      }
    },
    [navigation, userSelector.companyLegalName, userSelector.email],
  )

  const handleSendEmailClick = useCallback(() => {
    window.open(`mailto:${constants.helpEmail}`, '_self')
  }, [])

  const handlePageReady = useCallback(() => {
    isUserAdmin().then(isAdmin => {
      setIsBecky(isAdmin)
    })
  }, [])

  return (
    <PageContainer
      offsetLeft="20px"
      offsetRight="20px"
      offsetTop="20px"
      offsetMode="padding"
      onPageReady={() => handlePageReady()}
      allowNotifications={true}
      allowUnauthenticated={false}>
      <TextElement
        text="Get Started"
        theme="h1"
        alignment="center"
        display="block"
      />
      <Spacer direction="vertical" amount="10px" display="block" />
      <TextElement
        text="Choose an option below to get started on your next project!"
        theme="paragraph"
        alignment="center"
        display="block"
      />
      <Spacer direction="vertical" amount="20px" display="block" />
      <AlignmentContainer align="center" display="block">
        <Button
          text="Calculate the Materials Cost"
          theme="light"
          callback={() => handlePageClick('/quote-calculator')}
        />
        <Spacer direction="vertical" amount="15px" display="block" />
        <Button
          text="Estimate Your Materials Needed"
          theme="light"
          callback={() => handlePageClick('/materials-estimator')}
        />
        <Spacer direction="vertical" amount="15px" display="block" />
        <Button
          text="Initiate A Warranty"
          theme="light"
          callback={() => handlePageClick('/initiate-warranty')}
        />
      </AlignmentContainer>
      <Spacer direction="vertical" amount="30px" display="block" />
      <TextElement
        text="New Here?"
        theme="h1"
        alignment="center"
        display="block"
      />
      <Spacer direction="vertical" amount="20px" display="block" />
      <AlignmentContainer align="center" display="block">
        <Button
          text="Check Out Our Installer's Manual"
          theme="light"
          callback={() => handlePageClick('/installer-guide')}
        />
        <Spacer direction="vertical" amount="15px" display="block" />
        <Button
          text="Watch our Installer Videos"
          theme="light"
          callback={() => handlePageClick('youtube')}
        />
      </AlignmentContainer>
      <Spacer direction="vertical" amount="30px" display="block" />
      <TextElement
        text="Get Support"
        theme="h1"
        alignment="center"
        display="block"
      />
      <Spacer direction="vertical" amount="20px" display="block" />
      <AlignmentContainer align="center" display="block">
        <Button
          text="Send An Email"
          theme="light"
          callback={() => handleSendEmailClick()}
        />
      </AlignmentContainer>
      {isBecky ? (
        <>
          <Spacer direction="vertical" amount="30px" display="block" />
          <TextElement
            text="Administrators Only"
            theme="h1"
            alignment="center"
            display="block"
          />
          <TextElement
            text={`Hi ${userSelector.firstName}!`}
            theme="paragraph"
            italics={true}
            colour="grey"
            alignment="center"
            display="block"
          />
          <Spacer direction="vertical" amount="20px" display="block" />
          <AlignmentContainer align="center" display="block">
            <Button
              text="Company Management"
              theme="light"
              callback={() => handlePageClick('/admin/companies')}
            />
            <Spacer direction="vertical" amount="15px" display="block" />
            <Button
              text="Invited Users"
              theme="light"
              callback={() => handlePageClick('/admin/invited-users')}
            />
            <Spacer direction="vertical" amount="15px" display="block" />
            <Button
              text="User Management"
              theme="light"
              callback={() => handlePageClick('/admin/users')}
            />
          </AlignmentContainer>
          <Spacer direction="vertical" amount="50px" display="block" />
        </>
      ) : null}
    </PageContainer>
  )
}

export default Home
