import { AppEnvironment } from './types'

export const Environment: AppEnvironment = {
  ENV_CODE: 'prod',
  ENV_NAME: 'Production',
  consoleLogLevel: 1,
  FUNCTIONS_REGION: 'northamerica-northeast1',
  config: {
    apiKey: 'AIzaSyDd3CN6oMnOVqDcB2r7dEJCPj21rilTLQQ',
    authDomain: 'stormflow-installer-web-app.firebaseapp.com',
    projectId: 'stormflow-installer-web-app',
    storageBucket: 'stormflow-installer-web-app.appspot.com',
    messagingSenderId: '521117362551',
    appId: '1:521117362551:web:54ec621a05f4eee11d9972',
    measurementId: 'G-LKTY91MDRB',
  },
}
