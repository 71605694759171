import { DatabaseUser, DisplayUser, InvitedUser } from '../_types/user'

const defaultInvitedUser: InvitedUser = {
  id: '',
  email: '',
  companyId: '',
}

const defaultDatabaseUser: DatabaseUser = {
  id: '',
  accountCreatedTimestamp: Date.now(),
  displayName: '',
  email: '',
  firstName: '',
  lastName: '',
  loginProviderId: 'email',
  loginProviderName: 'Email',
  role: 'user',
  companyId: '',
}

const defaultDisplayUser: DisplayUser = {
  id: '',
  accountCreatedTimestamp: Date.now(),
  displayName: '',
  email: '',
  firstName: '',
  lastName: '',
  loginProviderId: 'email',
  loginProviderName: 'Email',
  role: 'user',
  companyId: '',
}

export { defaultDatabaseUser, defaultDisplayUser, defaultInvitedUser }
