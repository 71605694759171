import Fuse from 'fuse.js'
import { SearchMatrix } from './types'

const searchMatrix: SearchMatrix[] = [
  {
    page: 'home',
    title: 'Home',
    keywords: ['get started', 'support', 'email'],
  },
  {
    page: 'quote-calculator',
    title: 'Cost of Materials',
    keywords: ['quote', 'calculator', 'pricing', 'estimate', 'cost'],
  },
  {
    page: 'materials-estimator',
    title: 'Materials Estimator',
    keywords: ['materials', 'estimator', 'pricing', 'estimate', 'cost'],
  },
  {
    page: 'initiate-warranty',
    title: 'Initiate Warranty',
    keywords: ['warranty', 'initiate', 'claim'],
  },
  {
    page: 'installer-guide',
    title: 'Installer Guide',
    keywords: [
      'installer',
      'guide',
      'manual',
      'instructions',
      'installation',
      'how to',
      'how-to',
      'faq',
    ],
  },
]

const searchHaystack = (needle: string) => {
  const sanitizedNeedle = needle.trim().toLowerCase()

  const fuse = new Fuse(searchMatrix, {
    isCaseSensitive: false,
    minMatchCharLength: 2,
    threshold: 0.5,
    keys: ['title', 'keywords'],
  })

  const results = fuse.search(sanitizedNeedle)

  return results.map(result => ({
    title: result.item.title,
    page: result.item.page,
  }))
}

export { searchHaystack }
