/* eslint-disable @typescript-eslint/no-explicit-any */
let acrobatInstance: any = null

const getAcrobatInstance = () => {
  if ((window as any).AdobeDC) {
    acrobatInstance = new (window as any).AdobeDC.View({
      clientId: 'e483a388e9384124b9603f949d939974',
      divId: 'acrobat-viewer',
    })

    return acrobatInstance
  }
}

export { getAcrobatInstance }
