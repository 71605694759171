import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { getAuth, User as UserType } from 'firebase/auth'

import NavMenu from './components/nav-menu/NavMenu'
import TopOfPage from './components/top-of-page/TopOfPage'
import Home from './pages/home/Home'
import Login from './pages/login/Login'
import NotFound from './pages/not-found/NotFound'
import { getLocalStorageItem, removeLocalStorageItem } from './_data/storage'
import { useAppDispatch, useAppSelector } from './_globals/hooks'
import { RootState } from './_globals/state-store'
import { getEnvironmentSettings } from './_utilities/config'
import { NotificationContext } from './_globals/notifications/notification-context'
import { doesExist, getWindowHeight, getWindowWidth } from './_utilities/utils'
import { themeSlice } from './_globals/theme/theme-slice'
import { LocalUserCopy, UserStateUpdate } from './_globals/user/types'
import { userSlice } from './_globals/user/user-slice'
import InitiateWarranty from './pages/initiate-warranty/InitiateWarranty'
import { doFunctionsCall } from './_globals/custom-firebase/custom-firebase'
import { DatabaseCompany } from './_types/company'
import MaterialsEstimator from './pages/materials-estimator/MaterialsEstimator'
import QuoteCalculator from './pages/quote-calculator/QuoteCalculator'
import InstallerGuide from './pages/installer-guide/InstallerGuide'
import Search from './pages/search/Search'
import Users from './pages/admin/users/Users'
import User from './pages/admin/user/User'
import InvitedUsers from './pages/admin/invited-users/InvitedUsers'
import Companies from './pages/admin/companies/Companies'
import InvitedUser from './pages/admin/invited-user/InvitedUser'
import Company from './pages/admin/company/Company'

const themeAppSelector = (state: RootState) => state.theme

function App() {
  const themeSelector = useAppSelector(themeAppSelector)
  const dispatch = useAppDispatch()
  const { showNotification } = useContext(NotificationContext)
  const [isAuthorized, setIsAuthorized] = useState<boolean>(true)

  const handleWindowResize = useCallback(() => {
    dispatch(themeSlice.actions.setWindowWidth(getWindowWidth()))
    dispatch(themeSlice.actions.setWindowHeight(getWindowHeight()))
  }, [dispatch])

  const handleOnUserAuthChange = useCallback(
    (user: UserType) => {
      if (!doesExist(user)) {
        showNotification({
          title: 'Access Denied',
          type: 'error',
          dismissAfter: 5000,
        })

        removeLocalStorageItem('user')
        getAuth().signOut()
        setIsAuthorized(false)
      } else {
        setIsAuthorized(true)
      }
    },
    [showNotification],
  )

  const handleUpdateUser = useCallback(
    (companyId: string) => {
      doFunctionsCall('HighestPriority', {
        signature: 'Company-Get',
        companyId,
      }).then(data => {
        if (data.code === 200) {
          const company: DatabaseCompany = JSON.parse(data.data)

          const userUpdate: UserStateUpdate[] = [
            {
              attribute: 'companyCommonName',
              value: company.commonName,
            },
            {
              attribute: 'companyLegalName',
              value: company.officialName,
            },
          ]

          dispatch(userSlice.actions.updateUser(userUpdate))
        }
      })
    },
    [dispatch],
  )

  useEffect(() => {
    const appEnvironment = getEnvironmentSettings()

    if (appEnvironment.ENV_CODE === 'local') {
      const firebaseFunctions = getFunctions()
      connectFunctionsEmulator(firebaseFunctions, 'localhost', 5001)
    }

    getLocalStorageItem('user', null).then(rawUser => {
      if (rawUser) {
        const user: LocalUserCopy = JSON.parse(rawUser)

        if (user.id !== '' && user.lastSave && user.lastSave > 0) {
          const twoWeeks = 1_209_600_000

          if (Date.now() - user.lastSave < twoWeeks) {
            dispatch(userSlice.actions.reinitializeUser(user))
            handleUpdateUser(user.companyId)
          } else {
            dispatch(userSlice.actions.clearUser())
          }
        } else {
          removeLocalStorageItem('user')
          dispatch(userSlice.actions.clearUser())
        }
      }
    })

    const firebaseAuth = getAuth()
    firebaseAuth.onAuthStateChanged(handleOnUserAuthChange)
  }, [dispatch, handleOnUserAuthChange, handleUpdateUser])

  useEffect(() => {
    handleWindowResize()
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [handleWindowResize])

  return (
    <Router>
      <TopOfPage />
      <NavMenu
        hideNavMenu={themeSelector.showNavbar === false}
        pagePosition="top"
        sticky={true}>
        {themeSelector.allowNotifications ? <ToastContainer /> : null}
        <Routes>
          <Route path="/login" element={<Login />} />
          {isAuthorized ? (
            <>
              <Route path="/" element={<Home />} />
              <Route path="/initiate-warranty" element={<InitiateWarranty />} />
              <Route
                path="/materials-estimator"
                element={<MaterialsEstimator />}
              />
              <Route path="/quote-calculator" element={<QuoteCalculator />} />
              <Route path="/installer-guide" element={<InstallerGuide />} />
              <Route path="/search" element={<Search />} />
              <Route path="/admin/users" element={<Users />} />
              <Route path="/admin/user/:id" element={<User />} />
              <Route path="/admin/invited-users" element={<InvitedUsers />} />
              <Route path="/admin/invited-user/:id" element={<InvitedUser />} />
              <Route path="/admin/companies" element={<Companies />} />
              <Route path="/admin/company/:id" element={<Company />} />

              <Route path="/home" element={<Navigate to="/" />} />
              <Route path="*" element={<NotFound />} />
            </>
          ) : (
            <Route path="*" element={<Navigate to="/login" />} />
          )}
        </Routes>
      </NavMenu>
    </Router>
  )
}

export default App
