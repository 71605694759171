import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '../../../components/button/Button'
import Loading from '../../../components/loading/Loading'
import PageContainer from '../../../components/page-container/PageContainer'
import Spacer from '../../../components/spacer/Spacer'
import Table from '../../../components/table/Table'
import { TableData } from '../../../components/table/types'
import TextElement from '../../../components/text/Text'
import { isUserAdmin } from '../../../_data/admin'
import { doFunctionsCall } from '../../../_globals/custom-firebase/custom-firebase'
import { DatabaseUser } from '../../../_types/user'

const Users = (): JSX.Element => {
  const navigation = useNavigate()
  const [userList, setUserList] = useState<DatabaseUser[]>([])
  const [isApiBusy, setIsApiBusy] = useState<boolean>(true)

  const handleViewUser = useCallback(
    (userId: string) => {
      navigation(`/admin/user/${userId}`)
    },
    [navigation],
  )

  const tableData = useMemo(() => {
    const rows: TableData[][] = []

    userList
      .sort((a, b) => {
        if (a.displayName < b.displayName) {
          return -1
        } else if (a.displayName > b.displayName) {
          return 1
        }

        return 0
      })
      .forEach(user => {
        const row: TableData[] = [
          {
            content: user.displayName,
          },
          {
            content: user.email,
          },
          {
            content: (
              <Button
                text="View User"
                theme="light"
                size="small"
                callback={() => handleViewUser(user.id)}
              />
            ),
          },
        ]

        rows.push(row)
      })

    return rows
  }, [handleViewUser, userList])

  const handlePageReady = useCallback(() => {
    isUserAdmin().then(isAdmin => {
      if (isAdmin === false) {
        navigation('/')
        return null
      }

      doFunctionsCall('Admin', {
        signature: 'User-GetAll',
      }).then(data => {
        if (data.code === 200) {
          const fetchedUsers: DatabaseUser[] = JSON.parse(data.data)
          setUserList(fetchedUsers)
        } else {
          console.error(data)
        }

        setIsApiBusy(false)
      })
    })
  }, [navigation])

  return (
    <PageContainer
      offsetLeft="20px"
      offsetRight="20px"
      offsetTop="20px"
      offsetMode="padding"
      allowNotifications={true}
      allowUnauthenticated={false}
      onPageReady={() => handlePageReady()}>
      <TextElement text="Users" theme="h1" alignment="center" display="block" />
      <Spacer direction="vertical" amount="20px" display="block" />
      {isApiBusy ? (
        <Loading type="large" />
      ) : (
        <Table
          headerDirection="horizontal"
          alignment="center"
          headers={[
            { content: 'Name' },
            { content: 'Email' },
            { content: 'Actions' },
          ]}
          content={tableData}
        />
      )}
      <Spacer direction="vertical" amount="50px" display="block" />
    </PageContainer>
  )
}

export default Users
